<template>
	<div class="home">
		<div class="header">
			<div class="left">
				<img src="@/assets/image/logo2x.png" alt="" class="logo2x" />
				<div class="name">道路之星</div>
			</div>
			<div class="right">
				<div class="item" v-for="(item, i) in menu" :key="i" :class="active == i && 'active'" @click="handleSelect(i)">
					{{item}}
				</div>
			</div>
		</div>
		<div class="bg2x">
			<img src="@/assets/image/bg2x.png" class="bgImg" alt="" />
			<div class="top">
				<div class="name text">道路之星</div>
				<div class="text ">
					<div class="subText">用于公路、铁路、城市道路主线、立交匝道、隧</div>
					<div class="subText">道的勘测设计与施工放样工作。</div>
				</div>
				<div class="text">软件分为电脑版+安卓版</div>
				<div class="buttom">
					<!-- <div class="buttomItem" @click="download('https://rdstar.oss-cn-beijing.aliyuncs.com/rdstar_pc')">电脑版下载</div>
					<div class="buttomItem" @click="download('https://rdstar.oss-cn-beijing.aliyuncs.com/rdstar_app')">APP下载</div> -->
					<div class="buttomItem" @click="download('pc')">电脑版下载</div>
					<div class="buttomItem" @click="download('app')">APP下载</div>
				</div>
			</div>
			<div class="bottom">
				<div class="item">
					<img src="@/assets/image/pc2x.png" class="itemImg" alt="" />
					<div class="text">
						<div class="name">电脑端</div>
						<div class="col">电脑端数据处理负责设计输入输出、设计成果的复核、现场采集数据的分析计算以及与计算器进行文件传输；</div>
					</div>
				</div>
				<div class="item" style="margin-left: 40px;">
					<img src="@/assets/image/phone2x.png" class="itemImg" alt="" />
					<div class="text">
						<div class="name">移动端</div>
						<div class="col">b安卓端负责现场的施工指导和相关数据的采集放样云端数据同步</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pics">
			<!-- <div class="item" v-for="(i, v) in 7" :key="i">
				<img :src="`../assets/image/pics${v}.jpg`" class="pImg" alt="" />
			</div> -->
			<div class="item">
				<img src="@/assets/image/pics1.jpg" class="pImg" alt="" />
			</div>
			<div class="item">
				<img src="@/assets/image/pics2.jpg" class="pImg" alt="" />
			</div>
			<div class="item">
				<img src="@/assets/image/pics3.jpg" class="pImg" alt="" />
			</div>
			<div class="item">
				<img src="@/assets/image/pics4.jpg" class="pImg" alt="" />
			</div>
			<div class="item">
				<img src="@/assets/image/pics5.jpg" class="pImg" alt="" />
			</div>
			<div class="item">
				<img src="@/assets/image/pics6.jpg" class="pImg" alt="" />
			</div>
			<div class="item">
				<img src="@/assets/image/pics7.jpg" class="pImg" alt="" />
			</div>
		</div>
		<div class="main">
			<div class="top">
				<div class="item">道路之星产品特点</div>
				<div class="text">史上第一个中文测量软件</div>
			</div>
			<div class="function">
				<div class="item">
					<img src="@/assets/image/function2x.png" class="fImg" alt="" />
					<div class="title">多应用场景</div>
					<div class="text">断链、任意线形、高程 超高加宽、边坡、结构架、桥 架、隧道三四等水准记录平差、控制网测量平差。</div>
				</div>
				<div class="item">
					<img src="@/assets/image/function2x.png" class="fImg" alt="" />
					<div class="title">操作简单</div>
					<div class="text">电脑输入数据，仿Excel设计，支持数据导出、动态图形显示。项目文件直接传输至计算器中，同时支持从计算器中手工输入数据。 </div>
				</div>
				<div class="item">
					<img src="@/assets/image/function2x.png" class="fImg" alt="" />
					<div class="title">数据安全</div>
					<div class="text">所有数据保存这一个项目文件，在计算器不能修改，野外再也不会为丢失数据而烦恼。程序文件采用进制格式，运行速度快，用户不能修改，确保计算结果的正确。</div>
				</div>
				<div class="item">
					<img src="@/assets/image/function2x.png" class="fImg" alt="" />
					<div class="title">线上控制</div>
					<div class="text">App直接控制全站仪、RTK进行测量工作。</div>
				</div>
			</div>
		</div>
		<div class="Footer">
			© 2023 - 道路之星软件开发工作室 -<div @click="link(0)" class="link">&nbsp;湘ICP备2023022657号-1</div>
			<div class="public link" @click="link(1)">湘公网安备43012402000823</div>
		</div>
	</div>
</template>

<script>
	// import NavBar from '@/components/NavBar.vue'
	import axios from 'axios';

	export default {
		name: 'HomeView',
		components: {
			// NavBar
		},
		data() {
			return {
				menu: ["首页", "特点", "软件下载", "联系我们"],
				active: 0,
			}
		},
		methods: {
			handleSelect(e) {
				this.active = e;
				this.$nextTick(() => {
					if (e % 2 != 1) {
						window.scrollTo({
							top: 0,
							behavior: 'smooth'
						});
					} else {
						window.scrollTo({
							top: 1800,
							behavior: 'smooth'
						});
					}
				})
			},
			download(v) {
				if(v == "pc") {
					let pcUrl = sessionStorage.getItem('pcUrl') || null; // 获取数据
					if(pcUrl) window.location.href = pcUrl;
					else {
						let pc = "https://rdstar.com.cn:8016/api/update/updateAddressPc";
						axios.get(pc)
						.then(res => {
							let url = res?.data?.result || null;
							if(res.status == 200 && res.data && url) {
								window.location.href = url;
								sessionStorage.setItem('pcUrl', url);// 存储数据
							}else this.$message.error('错了哦，请联系管理人员。' + res?.status || "");
						})
						.catch(error => {
								this.$message.error('错了哦，请联系管理人员。' + res?.status || "");
						});
					}
				}else {
					let appUrl = sessionStorage.getItem('appUrl') || null; // 获取数据
					if(appUrl) window.location.href = appUrl;
					else {
						let app = "https://rdstar.com.cn:8016/api/update/updateAddressApp";
						axios.get(app)
						.then(res => {
							let url = res?.data?.result || null;
							if(res.status == 200 && res.data && url) {
								window.location.href = url;
								sessionStorage.setItem('appUrl', url);// 存储数据
							}else this.$message.error('错了哦，请联系管理人员。' + res?.status || "");
						})
						.catch(error => {
								this.$message.error('错了哦，请联系管理人员。' + res?.status || "");
						});
					}
				}
				// window.location.href = url
			},
			link(v) {
				if(v) window.open("https://beian.mps.gov.cn/#/query/webSearch?code=43012402000823", '_blank');
				else window.open("https://beian.miit.gov.cn/#/Integrated/index", '_blank');
			}
		}
	}
</script>
<style lang="scss">
	@import "HomeView.scss";
</style>